const testData = null;

const markerIconPath = 'M 0, 0 m -5, 0 a 5,5 0 1,0 10,0 a 5,5 0 1,0 -10,0';

const regularMarkerIconParams = {
  path: markerIconPath,
  fillColor: '#3AB5DB',
  fillOpacity: 1,
  strokeColor: 'white',
  strokeWeight: 2,
  scale: 1.5,
  labelOrigin: new google.maps.Point(0, -8)
};

const regularStopLabelParamsBase = {
  color: 'white',
  fontSize: '14px',
  className: 'marker-label'
};

const mustStopMarkerIconParams = {
  path: markerIconPath,
  fillColor: 'yellow',
  fillOpacity: 1,
  strokeColor: 'black',
  strokeWeight: 2.5,
  scale: 2,
  labelOrigin: new google.maps.Point(0, -9)
};

const mustStopLabelParamsBase= {
  color: 'black',
  fontSize: '16px',
  className: 'must-stop-marker-label'
};

const routePolylineStrokeParamsBase = {
  geodesic: true,
  strokeColor: 'white',
  strokeOpacity: 1.0,
  strokeWeight: 6,
};

const routePolylineParamsBase = {
  geodesic: true,
  strokeColor: '#3AB5DB',
  strokeOpacity: 1.0,
  strokeWeight: 4,
};

let map = null;

const initMap = () => {
  map = new google.maps.Map(document.getElementById('googleMap'), {
    center: {lat: 59.436962, lng: 24.753574},
    zoom: 10,
    mapId: 'caa380be48d3aa21',
    zoomControl: false,
    streetViewControl: false,
    mapTypeControl: false
  });

  const zoomDisplay = document.getElementById('zoom-level');
  zoomDisplay.innerText = 'Zoom level: ' + map.getZoom();

  map.addListener('zoom_changed', () => {
    zoomDisplay.innerText = 'Zoom level: ' + map.getZoom();
  });
};

const createRoutesInfoDiv = () => {
  const routesInfoElement = document.createElement('div');

  routesInfoElement.style.position = 'absolute';
  routesInfoElement.style.left = '25px';
  routesInfoElement.style.top = '25px';
  routesInfoElement.style.minWidth = '0px';
  routesInfoElement.style.minHeight = '0px';
  routesInfoElement.style.padding = '5px';
  routesInfoElement.style.backgroundColor = '#ffffff';
  routesInfoElement.style.border = '2px solid #000';
  routesInfoElement.style.boxShadow = '0 2px 5px rgba(0, 0, 0, 0.2)';
  routesInfoElement.style.display = 'inline-block';

  const textContent = document.createElement('span');
  textContent.textContent = '';
  textContent.id = 'routesInfoText';
  textContent.style.fontSize = '12px';
  textContent.style.textAlign = 'left';
  textContent.style.whiteSpace = 'nowrap';

  routesInfoElement.appendChild(textContent);

  return routesInfoElement;
}

const generateGeoJson = (points, route, stopNames) => {
  const geoJson = {
    type: 'FeatureCollection',
    features: []
  };

  const routeCoordinates = route.map(coord => [coord.lon, coord.lat]);

  geoJson.features.push({
    type: 'Feature',
    geometry: {
      type: 'LineString',
      coordinates: routeCoordinates
    },
    properties: {
      name: 'Route'
    }
  });

  points.forEach(point => {
    const name = point.name;
    const desc = stopNames.includes(name.toLowerCase()) ? 'must-stop' : '';
    geoJson.features.push({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [point.lon, point.lat]
      },
      properties: {
        name: name,
        description: desc
      }
    });
  });

  return geoJson;
};

const splitAndTrim = text => {
  return text.split(',').map(item => item.trim().toLowerCase());
}

const layerListElement = document.getElementById('layersList');

const addLayerToList = routeName => {
  const existing = document.getElementById(routeName);
  if (existing) return;

  const label = document.createElement('label');
  const labelCheckBox = document.createElement('input');
  labelCheckBox.type = 'checkbox';
  labelCheckBox.id = routeName;
  labelCheckBox.checked = true;
  labelCheckBox.addEventListener('change', function () {
    toggleLayerVisibility(routeName, this.checked);
  });

  label.appendChild(labelCheckBox);
  const text = document.createTextNode(routeName);
  label.appendChild(text);
  const listItem = document.createElement('li');
  listItem.appendChild(label);
  layerListElement.appendChild(listItem);
};

const mapLayers = {};
let saveData = {};
const visibility = {};

const toggleLayerVisibility = (layerName, show) => {
  const features = mapLayers[layerName];
  visibility[layerName] = show;

  const text = Object.keys(visibility)
  .filter(key => visibility[key])
  .sort()
  .join(" ");

  document.getElementById('routesInfoText').innerText = text;

  for (let i = 0; i < features.length; i++) {
    features[i].setMap(show ? map : null);
  }
};

const addLayerToMap = (layerName, geoJson) => {
  if (mapLayers[layerName]) {
    toggleLayerVisibility(layerName, false);
  }
  mapLayers[layerName] = [];

  const route = geoJson.features[0];

  const routeCoordinates = route.geometry.coordinates.map(coord => ({
    lat: coord[1],
    lng: coord[0]
  }));

  const routePolylineStrokeParams = {...routePolylineStrokeParamsBase, path: routeCoordinates};
  const routePolylineStroke = new google.maps.Polyline(routePolylineStrokeParams);

  const routePolylineParams = {...routePolylineParamsBase, path: routeCoordinates};
  const routePolyline = new google.maps.Polyline(routePolylineParams);

  mapLayers[layerName].push(routePolylineStroke);
  mapLayers[layerName].push(routePolyline);

  routePolylineStroke.setMap(map);
  routePolyline.setMap(map);

  const geoJsonPoints = geoJson.features.slice(1);

  geoJsonPoints.forEach(feature => {
    const coords = feature.geometry.coordinates;
    const position = {lat: coords[1], lng: coords[0]};

    const isMustStop = feature.properties.description === 'must-stop';
    const stopName = feature.properties.name;

    let marker;
    if (isMustStop) {
      const labelParams = {...mustStopLabelParamsBase, text: stopName};

      marker = new google.maps.Marker({
        position,
        map,
        icon: mustStopMarkerIconParams,
        label: labelParams
      });
    } else {
      const labelParams = {...regularStopLabelParamsBase, text: stopName};

      marker = new google.maps.Marker({
        position,
        map,
        icon: regularMarkerIconParams,
        label: labelParams
      });
    }
    mapLayers[layerName].push(marker);
  });

  toggleLayerVisibility(layerName, true);
};

window.addRouteButtonClicked = () => {
  const textarea = document.getElementById('rawJsonInput');

  const data = JSON.parse(textarea.value).data.pattern;
  const geometry = data.geometry;
  const stops = data.stops;
  const routeName = data.route.shortName;

  const stopsInput = document.getElementById('stopsInput');
  const stopNames = splitAndTrim(stopsInput.value);

  const geoJson = generateGeoJson(stops, geometry, stopNames);

  addLayerToList(routeName);
  addLayerToMap(routeName, geoJson);

  saveData[routeName] = geoJson;

  textarea.value = '';
};

window.downloadButtonClicked = () => {
  const jsonString = JSON.stringify(saveData);
  const blob = new Blob([jsonString], {type: 'application/json'});
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  const now = new Date();
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZone: 'Europe/Tallinn',
    hour12: false
  };
  const dateString = now.toLocaleString('en-GB', options).replace(/[/]/g, '-').replace(/[, ]+/g, '_');

  a.download = `bussiliinid_${dateString}.json`;
  a.click();

  URL.revokeObjectURL(url);
};

const hydrate = data => {
  Object.entries(data).forEach(([routeName, geoJson]) => {
    addLayerToList(routeName);
    addLayerToMap(routeName, geoJson);
  });
};

document.getElementById('uploadInput').addEventListener('change', (event) => {
  const file = event.target.files[0];

  if (file) {
    const reader = new FileReader();

    reader.onload = (e) => {
      try {
        saveData = JSON.parse(e.target.result);
        hydrate(saveData);
      } catch (error) {
        console.error('Error parsing JSON:', error);
      }
    };

    reader.readAsText(file);
  } else {
    alert('No file selected');
  }
});

window.onload = () => {
  initMap();

  const routesInfoDiv = createRoutesInfoDiv();
  document.getElementById('googleMap').appendChild(routesInfoDiv);

  if (testData) hydrate(testData);
};
